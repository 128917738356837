import React from 'react';
import './App.css';
import qs from 'query-string';
import { sumBy } from 'loadsh';

const INDEXES = [
  {
    name: '0 ~ 1',
    min: 0,
    max: 1,
    ratio: 0.1,
  },
  {
    name: '1 ~ 1.5',
    min: 1,
    max: 1.5,
    ratio: 0.15,
  },
  {
    name: '1.5 ~ 2',
    min: 1.5,
    max: 2,
    ratio: 0.2,
  },
  {
    name: '2 ~ 3',
    min: 2,
    max: 3,
    ratio: 0.25,
  },
  {
    name: '3 ~ 100',
    min: 3,
    max: 100,
    ratio: 0.3,
  },
];

function App() {
  const params = qs.parse(window.location.search);
  const isDetail = params.key === '888';

  const [indexes, setIndexes] = React.useState(INDEXES);

  const [estimateProfitAmount, setEstimateProfitAmount] = React.useState(5000); // 目标销售盈利金额
  const [actualProfitAmount, setActualProfitAmount] = React.useState(10000); // 实际销售盈利金额
  const [wageAmount, setWageAmount] = React.useState(3000); // 总工资
  const [operationRatio, setOperatioRatio] = React.useState(1.5); // 运营倍数
  const [minProfitRatio, setMinProfitRatio] = React.useState(0.5); // 最低完成指数

  const minProfitBaseAmount =
    parseFloat(minProfitRatio || 0) * estimateProfitAmount; // 最低盈利基数

  const rows = indexes.map((row) => {
    const minEstimateAmount = row.min * parseFloat(estimateProfitAmount || 0);
    const maxEstimateAmount = row.max * parseFloat(estimateProfitAmount || 0);

    let gradientAmount = 0;

    if (minProfitBaseAmount > actualProfitAmount) {
      gradientAmount = 0;
    } else if (
      actualProfitAmount <= maxEstimateAmount &&
      actualProfitAmount >= minEstimateAmount
    ) {
      gradientAmount = actualProfitAmount - minEstimateAmount;
    } else if (actualProfitAmount > maxEstimateAmount) {
      gradientAmount = maxEstimateAmount - minEstimateAmount;
    } else if (actualProfitAmount < minEstimateAmount) {
      gradientAmount = 0;
    }

    gradientAmount = gradientAmount * row.ratio;

    return {
      minEstimateAmount,
      maxEstimateAmount,
      gradientAmount,
    };
  });

  const finalSalesCommissionAmount = sumBy(rows, 'gradientAmount');
  const finalCompanyProfit =
    parseFloat(actualProfitAmount || 0) -
    parseFloat(operationRatio || 0) * parseFloat(wageAmount || 0) -
    finalSalesCommissionAmount;

  return (
    <div className="container mx-auto">
      <div>
        <p>
          引言：
          销售提成鼓励多劳多得，突破难度获得更多。越多劳，越多得，比例随着为公司产生的利润按照梯度逐步增加。
          销售的职责范围如下，
        </p>
        <p>1.客户引入 </p>
        <p>2.客户维护 </p>
        <p>3.客户回款 </p>
        <p>4.现有客户群的定义 </p>
        <p>5.销售产品的策划 </p>
        <p>6.销售策略的制定 </p>
      </div>
      <div className="mt-5">
        <p>
          公式： 销售提成 = (实际销售盈利金额 - 总工资 x 运营倍数) x
          各个梯度的提成比例
        </p>

        <p>实际销售盈利金额 = 规定时间内的已经Closed Order的销售毛利</p>
        <p>总工资 = 基本工资 + 社保等福利保障</p>
        <p>运营倍数 = (1 - 3)，公司的管理成本的运营倍数</p>
        <p>提成比例 = (5% - 30%)，公司给与销售的提成比例</p>
        <p>
          完成指数 = 完成盈利金额 /
          目标盈利金额，目标销售金额的完成比例，分梯度和提成比例关联
        </p>
      </div>

      <div className="mt-5">
        <table class="border border-collapse border-spacing-2	table-auto">
          <tbody>
            <tr>
              <td className="border p-2">完成指数</td>
              {indexes.map((row) => (
                <td className="border p-2" key={row.name}>
                  {row.name}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border p-2">提成比例</td>
              {indexes.map((row, i) => (
                <td className="border p-2" key={row.name}>
                  <input
                    style={{ width: '80px' }}
                    className="ml-1"
                    value={row.ratio ? row.ratio * 100 : ''}
                    type="number"
                    onChange={(e) => {
                      const _index = [...indexes];
                      _index[i] = {
                        ...row,
                        ratio: parseFloat(e.target.value || 0) / 100,
                      };
                      setIndexes(_index);
                    }}
                  />
                  %
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-5">
        <table class="border border-collapse border-spacing-2	table-auto">
          <tbody>
            <tr>
              <td className="border p-2">目标销售盈利金额</td>
              <td className="border p-2">
                $
                <input
                  className="ml-1"
                  value={estimateProfitAmount || ''}
                  type="number"
                  onChange={(e) => setEstimateProfitAmount(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="border p-2">实际销售盈利金额</td>
              <td className="border p-2">
                $
                <input
                  className="ml-1"
                  value={actualProfitAmount || ''}
                  type="number"
                  onChange={(e) => setActualProfitAmount(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="border p-2">总工资</td>
              <td className="border p-2">
                $
                <input
                  className="ml-1"
                  value={wageAmount || ''}
                  type="number"
                  onChange={(e) => setWageAmount(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="border p-2">运营倍数</td>
              <td className="border p-2">
                <input
                  className="ml-1"
                  value={operationRatio || ''}
                  type="number"
                  onChange={(e) => setOperatioRatio(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="border p-2">最低完成指数</td>
              <td className="border p-2">
                <input
                  className="ml-1"
                  value={minProfitRatio || ''}
                  type="number"
                  onChange={(e) => setMinProfitRatio(e.target.value)}
                />
              </td>
            </tr>

            <tr>
              <td className="border p-2">最低盈利基数</td>
              <td className="border p-2">
                $<span className="ml-1">{minProfitBaseAmount.toFixed(2)}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-5">
        <table class="border border-collapse border-spacing-2	table-auto">
          <tbody>
            <tr>
              <td className="border p-2">完成指数区间</td>
              {indexes.map((row) => (
                <td className="border p-2" key={row.name}>
                  {row.name}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border p-2">完成盈利区间金额</td>
              {indexes.map((row, index) => (
                <td className="border p-2" key={row.name}>
                  ${rows[index]['minEstimateAmount'].toFixed(2)} - $
                  {rows[index]['maxEstimateAmount'].toFixed(2)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="border p-2">提成比例</td>
              {indexes.map((row) => (
                <td className="border p-2" key={row.name}>
                  {(row.ratio * 100).toFixed(2)}%
                </td>
              ))}
            </tr>
            <tr>
              <td className="border p-2">次梯度提成金额</td>
              {indexes.map((row, index) => (
                <td className="border p-2" key={row.name}>
                  ${rows[index]['gradientAmount'].toFixed(2)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5">
        <div>销售提成: ${finalSalesCommissionAmount.toFixed(2)}</div>
        <div>公司利润: ${finalCompanyProfit.toFixed(2)}</div>
      </div>
    </div>
  );
}

export default App;
